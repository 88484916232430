@use '../includes' as *;
@use 'sass:color';

.video_help {
  @include body-copy-3($pebble);

  a {
    color: currentcolor;
    font-weight: $bold;

    &:hover,
    &:focus {
      color: color.mix($midnight, $pebble, 30%);
    }
  }

  @include breakpoint($md) {
    @include body-copy-2($fog);

    margin-block-end: $g8;
  }
}
